import { useOrganizationPrograms } from '@guider-global/sanity-hooks';
import { SanitySettings, TOTPSurvey } from '@guider-global/shared-types';

import { OTPSurveyForm } from 'forms';
import { interpolate } from 'functions';
import { useOTPSurveys } from 'hooks/useOTPSurveys';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SurveyCardView, SurveyThankYouView } from 'views';

type OTPSurveyContainerProps = {
  survey: TOTPSurvey;
  settings: SanitySettings | undefined;
};

export function OTPSurveyContainer({
  survey,
  settings,
}: OTPSurveyContainerProps) {
  const organizationSlug = survey.organizationSlug;
  const [surveySubmitted, setSurveySubmitted] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const localeCode = searchParams.get('locale') ?? 'en_GB';
  const time = searchParams.get('time');

  const { reqOTPSurveys } = useOTPSurveys({});
  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(survey?.programSlug);
  const programType = program?.program_details?.program_type;
  const programTypeVerb = programType?.program_type_text.common?.verb ?? '';

  const handleSessionLog = useCallback(async () => {
    await reqOTPSurveys({
      method: 'PATCH',
      url: `/otp/surveys/${survey.id}?userId=${survey.user}&time=${time}`,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!survey.oneClick) return;
    handleSessionLog();
  }, [handleSessionLog, survey.oneClick]);

  const surveyProps = useMemo(() => {
    switch (survey.type) {
      case 'guide-feedback': {
        const typeSettings = settings?.guider_surveys?.guide_feedback_survey;
        const thankYouSettings = typeSettings?.thank_you_state;
        const { metaData } = survey;

        const title = typeSettings?.title;
        const lastName = metaData.guideLastName;
        const firstName = metaData.guideFirstName;
        const roleSingular =
          program?.program_details?.program_type?.program_type_text.common
            ?.guide?.singular;
        const programTypeNoun =
          program?.program_details?.program_type?.program_type_text.common
            ?.noun;

        return {
          title,
          firstName,
          lastName,
          thankYouSettings,
          roleSingular,
          programTypeNoun,
        };
      }
      case 'goals-feedback': {
        const typeSettings = settings?.guider_surveys?.goals_survey;
        const thankYouSettings = typeSettings?.thank_you_state;
        const { metaData } = survey;

        const title = typeSettings?.title;
        if (metaData.role === 'trainee') {
          const lastName = metaData.guideLastName;
          const firstName = metaData.guideFirstName;
          const roleSingular =
            program?.program_details?.program_type?.program_type_text.common
              ?.guide?.singular;

          return { title, firstName, lastName, thankYouSettings, roleSingular };
        } else {
          const lastName = metaData.traineeLastName;
          const firstName = metaData.traineeFirstName;
          const roleSingular =
            program?.program_details?.program_type?.program_type_text.common
              ?.trainee?.singular;

          return { title, firstName, lastName, thankYouSettings, roleSingular };
        }
      }
      case 'skills-feedback': {
        const typeSettings = settings?.guider_surveys?.skills_survey;
        const thankYouSettings = typeSettings?.thank_you_state;
        const { metaData } = survey;
        const title = typeSettings?.title;

        if (metaData.role === 'trainee') {
          const lastName = metaData.guideLastName;
          const firstName = metaData.guideFirstName;
          const roleSingular =
            program?.program_details?.program_type?.program_type_text.common
              ?.guide?.singular;

          return { title, firstName, lastName, thankYouSettings, roleSingular };
        } else {
          const lastName = metaData.traineeLastName;
          const firstName = metaData.traineeFirstName;
          const roleSingular =
            program?.program_details?.program_type?.program_type_text.common
              ?.trainee?.singular;

          return { title, firstName, lastName, thankYouSettings, roleSingular };
        }
      }
      case 'session-log': {
        const typeSettings = settings?.guider_surveys?.log_session_survey;
        const thankYouSettings = typeSettings?.thank_you_state;
        return { thankYouSettings };
      }
      default:
        break;
    }
  }, [
    program?.program_details?.program_type?.program_type_text.common,
    settings,
    survey,
  ]);

  if (surveySubmitted || survey.oneClick) {
    return (
      <SurveyThankYouView
        title={surveyProps?.thankYouSettings?.title}
        description={interpolate(
          surveyProps?.thankYouSettings?.description ?? '',
          {
            roleSingular: surveyProps?.roleSingular ?? '',
          },
        )}
        buttonLabel={
          surveyProps?.thankYouSettings?.return_to_the_platform_button_label
        }
      />
    );
  }

  return (
    <SurveyCardView
      firstName={surveyProps?.firstName}
      lastName={surveyProps?.lastName}
      title={surveyProps?.title}
      programName={program?.metadata.program_name}
      roleSingular={surveyProps?.roleSingular}
      programTypeNoun={surveyProps?.programTypeNoun}
    >
      <OTPSurveyForm
        firstName={surveyProps?.firstName}
        lastName={surveyProps?.lastName}
        programTypeVerb={programTypeVerb}
        roleSingular={surveyProps?.roleSingular}
        survey={survey}
        onSuccess={() => setSurveySubmitted(true)}
      />
    </SurveyCardView>
  );
}
