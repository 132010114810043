import { useProfileImage } from '@guider-global/azure-storage-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useBaseLanguage,
  useSettings,
} from '@guider-global/sanity-hooks';
import { KeyedProps } from '@guider-global/shared-types';
import { KeyStatProps, LeaderboardRow } from '@guider-global/ui';
import { useLeaderboardPositions, useLocalization } from 'hooks';
import { useState } from 'react';
import { ProgressLeaderboardModalView } from 'views';

export interface ProgressLeaderboardModalContainerProps {
  open: boolean;
  onClose?: (() => void) | undefined;
  keyStats: KeyedProps<KeyStatProps>[];
  userContributions: number;
  showLeaderboard?: boolean;
}
const PAGE_LIMIT = 10;

export function ProgressLeaderboardModalContainer({
  open,
  onClose,
  keyStats,
  userContributions,
  showLeaderboard,
}: ProgressLeaderboardModalContainerProps) {
  const [page, setPage] = useState<number>(1);
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const { getProfileImage } = useProfileImage();
  const { settings } = useSettings({});
  const defaultAvatarSrc = buildSanityImageUrl({
    source: settings?.static_media?.relationships?.default_user_avatar ?? '',
  });
  const {
    leaderboardPositions = [],
    leaderboardMeta,
    isLoadingLeaderboardPositions,
  } = useLeaderboardPositions({
    params: {
      pageLimit: PAGE_LIMIT,
      page,
      enableCount: showLeaderboard,
      enableRank: true,
    },
    options: {
      revalidateOnMount: showLeaderboard,
      revalidateIfStale: showLeaderboard,
      revalidateOnFocus: showLeaderboard,
      revalidateOnReconnect: showLeaderboard,
    },
  });
  const rowCount = leaderboardMeta?.pagination?.isPaginated
    ? leaderboardMeta?.pagination?.pageCount
    : leaderboardPositions.length;

  const leaderboardRows: LeaderboardRow[] = leaderboardPositions?.map(
    ({ profilePicture, userPicture, rank, ...rest }, index) => ({
      ...rest,
      rank: rank ?? index,
      avatarSrc:
        getProfileImage({
          profilePicture,
          userPicture,
        }) ?? defaultAvatarSrc,
    }),
  );
  return (
    <ProgressLeaderboardModalView
      open={open}
      onClose={onClose}
      heading={baseLanguage?.dashboard?.leaderboard?.modal?.title}
      yourContributionsLabel={
        baseLanguage?.dashboard?.leaderboard?.modal?.your_contributions_label
      }
      dialMetricLabel={`${userContributions}`}
      dialMetricDescription={
        baseLanguage?.dashboard?.leaderboard?.counts?.contributions_label
      }
      keyStats={keyStats}
      leaderboardRows={leaderboardRows ?? []}
      loadingRows={isLoadingLeaderboardPositions}
      rankColumnLabel={
        baseLanguage?.dashboard?.leaderboard?.modal?.table?.rank_column_label
      }
      profileColumnLabel={
        baseLanguage?.dashboard?.leaderboard?.modal?.table?.name_column_label
      }
      contributionsColumnLabel={
        baseLanguage?.dashboard?.leaderboard?.modal?.table
          ?.contributions_column_label
      }
      page={page}
      pageSize={PAGE_LIMIT}
      rowCount={rowCount}
      handlePageChange={setPage}
    />
  );
}
