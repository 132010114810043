import { useRelationships, useUsers } from '@guider-global/front-end-hooks';
import {
  getFriendlyDateFormat,
  getLanguageFromCMS,
  getSubDomain,
  getTimeFormat,
  isValidDate,
  useIsSafariOrIOS,
} from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { IRelationship } from '@guider-global/shared-types';
import { GroupAvatar, SurveyModal } from '@guider-global/ui';
import { SurveyAvatar } from 'components';
import { format } from 'date-fns';
import { SurveyForm } from 'forms';
import {
  interpolate,
  isSurveyFieldForGuide,
  isSurveyFieldForTrainee,
} from 'functions';
import { useLocalization, useSurveyResources, useSurveys } from 'hooks';
import { useContext, useEffect, useState } from 'react';
import { SurveyModalsContext } from '../SurveyModalsContext';

export function PostRelationshipSurveyModalContainer() {
  const { surveyType, setSurveyType, isSurveySubmitted } =
    useContext(SurveyModalsContext);
  const IsSafariOrIOS = useIsSafariOrIOS();

  // Local state
  const [concludedRelationship, setConcludedRelationship] =
    useState<IRelationship>();
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);

  const [open, setOpen] = useState<boolean>(true);

  // Sanity organization programs
  const { programs, isLoadingPrograms } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });

  // Users
  const { users, isLoadingUsers } = useUsers({});
  const user = users?.at(0);

  // Time and date format

  const { baseLanguage } = useBaseLanguage({ localeCode });
  const locale = getLanguageFromCMS(baseLanguage?.language_code);
  const FormattedTime = getTimeFormat(baseLanguage?.time_format);
  const FormattedDate = getFriendlyDateFormat(baseLanguage?.date_format);

  // Relationships
  const { relationships, isLoadingRelationships } = useRelationships({});

  // Surveys
  const { surveys, isLoadingSurveys } = useSurveys({});

  const isLoadingPostRelationshipSurveyResources =
    isLoadingPrograms ||
    isLoadingRelationships ||
    isLoadingUsers ||
    isLoadingSurveys;

  const isDelayed =
    window.sessionStorage.getItem(`post-relationship-delay`) === 'true' ??
    false;

  useEffect(() => {
    if (isLoadingPostRelationshipSurveyResources) {
      return;
    }

    const concludedRelationshipWithoutSurvey = relationships?.find(
      (relationship) => {
        const isGuide = relationship.guideProfiles?.at(0)?.userId === user?.id;
        const sessionCount = relationship.sessions?.length ?? 0;

        const program = programs.find(
          (program) => program.metadata.id.current === relationship.programSlug,
        );
        const survey = program?.surveys?.post_relationship_survey;
        const surveyFields = survey?.fields ?? [];
        const surveyFieldsByRole = surveyFields.filter((surveyField) =>
          isGuide
            ? isSurveyFieldForGuide(surveyField, sessionCount)
            : isSurveyFieldForTrainee(surveyField, sessionCount),
        );
        const surveyStartDate = survey?.start_date;

        const isRelationshipConcludedAfterSurveyStartDate =
          surveyStartDate &&
          isValidDate(surveyStartDate) &&
          relationship.concludedAt &&
          new Date(relationship.concludedAt) > new Date(surveyStartDate);

        const hasNotProvidedRelationshipFeedback = !surveys?.find(
          (survey) =>
            survey.type === 'post-relationship' &&
            survey.relationship === relationship.id,
        );

        return (
          survey?.enabled &&
          surveyFieldsByRole.length &&
          relationship.isConcluded &&
          isRelationshipConcludedAfterSurveyStartDate &&
          hasNotProvidedRelationshipFeedback
        );
      },
    );

    setConcludedRelationship(concludedRelationshipWithoutSurvey);
  }, [
    isLoadingPostRelationshipSurveyResources,
    relationships,
    user?.id,
    programs,
    surveys,
  ]);

  useEffect(() => {
    if (
      !isLoadingSurveys &&
      !isSurveySubmitted &&
      !surveyType &&
      concludedRelationship
    ) {
      setSurveyType('post-relationship');
    }
  }, [
    isLoadingSurveys,
    isSurveySubmitted,
    surveyType,
    concludedRelationship,
    setSurveyType,
  ]);

  const {
    title,
    description,
    heading,
    subheading,
    profile,
    surveyFields,
    isGuide,
    isGroupProgram,
    isSurveyEnabled,
    isOnGuiderVideoPage,
  } = useSurveyResources({
    surveyType: 'post_relationship_survey',
    relationship: concludedRelationship,
    user,
  });

  useEffect(() => {
    if (!isDelayed) {
      setOpen(true);
    }
  }, [isDelayed, open]);

  if (
    isSurveySubmitted ||
    surveyType !== 'post-relationship' ||
    !surveyType ||
    !concludedRelationship?.programSlug ||
    !isSurveyEnabled ||
    !concludedRelationship ||
    !surveyFields.length ||
    isOnGuiderVideoPage ||
    isDelayed
  ) {
    return <></>;
  }
  const concludedAt = concludedRelationship?.concludedAt;

  const time = concludedAt
    ? format(new Date(concludedAt), FormattedTime, { locale })
    : '';

  const date = concludedAt
    ? format(new Date(concludedAt), FormattedDate, { locale })
    : '';

  const interpolatedDescription = interpolate(description, {
    time,
    date,
  });

  function handleNotNow() {
    window.sessionStorage.removeItem('post-relationship-delay');
    window.sessionStorage.setItem('post-relationship-delay', 'true');
    setOpen(false);
  }

  return (
    <SurveyModal
      open={open}
      title={title}
      description={interpolatedDescription}
      heading={heading ?? ''}
      subheading={subheading}
      Avatar={
        !IsSafariOrIOS ? (
          isGroupProgram ? (
            <GroupAvatar width={64} height={64} size="48px" />
          ) : (
            <SurveyAvatar
              relationshipId={concludedRelationship.id}
              isGuide={isGuide ?? false}
            />
          )
        ) : (
          <></>
        )
      }
    >
      <SurveyForm
        title={title}
        programSlug={concludedRelationship.programSlug}
        profile={profile?.id}
        type={'post-relationship'}
        relationship={concludedRelationship.id}
        surveyFields={surveyFields}
        isGuide={isGuide}
        handleNotNow={handleNotNow}
      />
    </SurveyModal>
  );
}
