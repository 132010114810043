import { ProgressLeaderboardCardView } from '../../../views';

import { useProfileImage } from '@guider-global/azure-storage-hooks';
import { useProfiles } from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useBaseLanguage,
  useOrganization,
  useSettings,
} from '@guider-global/sanity-hooks';
import { LeaderboardRow } from '@guider-global/ui';
import { Skeleton } from '@mui/material';
import { useLeaderboardPositions, useLocalization } from 'hooks';
import { useState } from 'react';
import { ProgressLeaderboardModalContainer } from '../ProgressLeaderboardModalContainer';

export function ProgressLeaderboardContainer() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const organizationSlug = getSubDomain();

  const { organization } = useOrganization({
    organizationSlug,
  });

  const showLeaderboard = Boolean(organization?.dashboard_page?.leaderboard);
  const { settings } = useSettings({});
  const defaultAvatarSrc = buildSanityImageUrl({
    source: settings?.static_media?.relationships?.default_user_avatar ?? '',
  });
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { getProfileImage } = useProfileImage();
  const { profiles } = useProfiles({});
  const profile = profiles?.at(0);

  const {
    leaderboardPositions: usersLeaderboardPositions = [],
    isLoadingLeaderboardPositions: isLoadingUsersLeaderboardPositions,
  } = useLeaderboardPositions({
    params: {
      profileId: profile?.id,
      pageLimit: 1,
      enableRank: showLeaderboard,
      enableCount: false,
    },
    options: {
      revalidateOnMount: Boolean(profile?.id),
      revalidateIfStale: Boolean(profile?.id),
      revalidateOnFocus: Boolean(profile?.id),
      revalidateOnReconnect: Boolean(profile?.id),
    },
  });
  const usersLeaderboardPosition = usersLeaderboardPositions?.at(0);

  const { leaderboardPositions = [], isLoadingLeaderboardPositions } =
    useLeaderboardPositions({
      params: {
        pageLimit: 3,
        enableCount: false,
        enableRank: true,
      },
      options: {
        revalidateOnMount: showLeaderboard,
        revalidateIfStale: showLeaderboard,
        revalidateOnFocus: showLeaderboard,
        revalidateOnReconnect: showLeaderboard,
      },
    });

  const leaderboardRows: LeaderboardRow[] = [
    ...leaderboardPositions,
    ...usersLeaderboardPositions,
  ]?.map(({ profilePicture, userPicture, rank, ...rest }, index) => ({
    ...rest,
    rank: rank ?? index,
    avatarSrc:
      getProfileImage({
        profilePicture,
        userPicture,
      }) ?? defaultAvatarSrc,
  }));

  const userPosition = () => {
    const existsInPositions = leaderboardPositions.findIndex(
      (position) => position.id === usersLeaderboardPosition?.id,
    );
    if (existsInPositions > -1) return existsInPositions;
    return leaderboardRows.length - 1;
  };

  function handleOpenModal() {
    setShowModal(true);
  }
  function handleCloseModal() {
    setShowModal(false);
  }

  const keyStats = [
    {
      key: 'sessions',
      label: baseLanguage?.globals?.sessions?.sessions ?? 'Sessions',
      value: usersLeaderboardPosition?.sessionCount ?? 0,
    },
    {
      key: 'relationships',
      label:
        baseLanguage?.globals?.relationships?.relationships ?? 'Relationships',
      value: usersLeaderboardPosition?.relationshipCount ?? 0,
    },
    {
      key: 'goals',
      label: baseLanguage?.globals?.goals?.goals ?? 'Goals',
      value: usersLeaderboardPosition?.goalCount ?? 0,
    },
  ];

  if (isLoadingUsersLeaderboardPositions || isLoadingLeaderboardPositions) {
    return (
      <Skeleton
        variant="rounded"
        sx={{ minHeight: '420px', borderRadius: '16px' }}
      />
    );
  }

  return (
    <>
      <ProgressLeaderboardCardView
        keyStats={keyStats}
        dialMetricLabel={`${usersLeaderboardPosition?.contributions}`}
        dialMetricDescription={
          baseLanguage?.dashboard?.leaderboard?.counts?.contributions_label
        }
        leaderboardRows={leaderboardRows ?? []}
        selectedLeaderboardRow={userPosition()}
        viewLeaderboardLabel={
          baseLanguage?.dashboard?.leaderboard?.card
            ?.view_leaderboard_button_label
        }
        onViewLeaderboard={handleOpenModal}
        showLeaderboard={showLeaderboard}
      />
      <ProgressLeaderboardModalContainer
        open={showModal}
        onClose={handleCloseModal}
        keyStats={keyStats}
        userContributions={usersLeaderboardPosition?.contributions ?? 0}
        showLeaderboard={showLeaderboard}
      />
    </>
  );
}
