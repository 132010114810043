import { theme } from '@guider-global/ui';
import { ThemeProvider, useTheme } from '@mui/material';
import { deepMerge } from 'utils';
import { PostRelationshipSurveyModalContainer } from './PostRelationshipSurveyModalContainer';
import { PostSessionSurveyModalContainer } from './PostSessionSurveyModalContainer';
import { SurveyModalsProvider } from './SurveyModalsContext';
import { ThankYouSurveyModalContainer } from './ThankYouSurveyModalContainer';

export function SurveyModals() {
  const organizationTheme = useTheme();

  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  return (
    <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
      <SurveyModalsProvider>
        <PostSessionSurveyModalContainer />
        <PostRelationshipSurveyModalContainer />
        <ThankYouSurveyModalContainer />
      </SurveyModalsProvider>
    </ThemeProvider>
  );
}
