import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';

import { DashboardCard, Image, Stack, TextStack } from '@guider-global/ui';
import { Skeleton } from '@mui/material';
import Arrow from 'assets/arrow-icon.svg';
import NoPrograms from 'assets/no_programs_illustration.svg';
import { useLocalization } from 'hooks';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { GroupProgramMembershipsContainer } from '../GroupProgramMembershipsContainer';
import { IndividualProgramMembershipsContainer } from '../IndividualProgramMembershipsContainer';
import { useMemberships, useSettings } from '@guider-global/front-end-hooks';

export function ProgramMembershipsContainer() {
  const navigate = useNavigate();
  // Sanity Programs
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { programs, isLoadingPrograms } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });

  const { settings } = useSettings({
    options: { keepPreviousData: true },
  });
  const timezone = settings?.at(0)?.timezone ?? 'Europe/London';

  // Base Language
  const { baseLanguage } = useBaseLanguage({
    localeCode,
  });

  // Membership Summaries
  const { memberships, isLoadingMemberships } = useMemberships({});

  const programData = useMemo(
    () =>
      programs
        .filter((program) => {
          return memberships?.some(
            (membership) =>
              membership.programSlug === program.metadata.id.current,
          );
        })
        .map((program) => {
          const variation = program.program_details?.program_variation;
          return {
            program,
            variation,
          };
        }),
    [programs, memberships],
  );

  if (isLoadingMemberships || isLoadingPrograms) {
    return (
      <DashboardCard
        width={{ xs: '100%', md: '1136px' }}
        title={baseLanguage?.globals?.programs?.programs}
        cta={{
          label:
            baseLanguage?.dashboard?.program_memberships
              ?.view_all_programs_button_label,
          icon: <Image src={Arrow} height={'20px'} width={'20px'} />,
          onClick: () => navigate('/programs'),
        }}
      >
        <Skeleton
          variant="rounded"
          sx={{ minHeight: '420px', borderRadius: '16px' }}
        />
      </DashboardCard>
    );
  }

  if (programData.length === 0) {
    return (
      <DashboardCard
        width={{ xs: '100%', md: '1136px' }}
        title={baseLanguage?.globals?.programs?.programs}
        cta={{
          label:
            baseLanguage?.dashboard?.program_memberships
              ?.view_all_programs_button_label,
          icon: <Image src={Arrow} height={'20px'} width={'20px'} />,
          onClick: () => navigate('/programs'),
        }}
      >
        <Stack
          direction={'column'}
          alignSelf={'center'}
          alignItems={'center'}
          p={3}
          pb={6}
          gap={1}
        >
          <Image src={NoPrograms} height={'160px'} width={'240px'} />
          <TextStack
            spacing={1}
            size="medium"
            heading={
              baseLanguage?.dashboard?.program_memberships?.empty_state?.title
            }
            align="center"
            subtitles={[
              {
                text: baseLanguage?.dashboard?.program_memberships?.empty_state
                  ?.description,
                textAlign: 'center',
                color: 'text.secondary',
              },
            ]}
          />
        </Stack>
      </DashboardCard>
    );
  }

  return (
    <DashboardCard
      width={{ xs: '100%', md: '1136px' }}
      title={baseLanguage?.globals?.programs?.programs}
      cta={{
        label:
          baseLanguage?.dashboard?.program_memberships
            ?.view_all_programs_button_label,
        icon: <Image src={Arrow} height={'20px'} width={'20px'} />,
        onClick: () => navigate('/programs'),
      }}
    >
      {programData.map((program) =>
        program.variation === 'group' ? (
          <GroupProgramMembershipsContainer
            key={program.program._id}
            program={program.program}
            timezone={timezone}
          />
        ) : (
          <IndividualProgramMembershipsContainer
            key={program.program._id}
            program={program.program}
            timezone={timezone}
          />
        ),
      )}
    </DashboardCard>
  );
}
