import { useRelationships, useUsers } from '@guider-global/front-end-hooks';
import {
  getFriendlyDateFormat,
  getLanguageFromCMS,
  getSubDomain,
  getTimeFormat,
  isValidDate,
  useIsSafariOrIOS,
} from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { ISession } from '@guider-global/shared-types';
import { GroupAvatar, SurveyModal } from '@guider-global/ui';
import { SurveyAvatar } from 'components';
import { format } from 'date-fns';
import { SurveyForm } from 'forms';
import {
  interpolate,
  isSurveyFieldForGuide,
  isSurveyFieldForTrainee,
} from 'functions';
import {
  useLocalization,
  useSessions,
  useSurveyResources,
  useSurveys,
} from 'hooks';
import { useContext, useEffect, useState } from 'react';
import { SurveyModalsContext } from '../SurveyModalsContext';

export function PostSessionSurveyModalContainer() {
  const { surveyType, setSurveyType, isSurveySubmitted } =
    useContext(SurveyModalsContext);
  const IsSafariOrIOS = useIsSafariOrIOS();

  // Local state
  const [elapsedSession, setElapsedSession] = useState<ISession>();
  const [open, setOpen] = useState<boolean>(true);

  // Organization and Base language
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  // Sanity organization programs
  const { programs, isLoadingPrograms } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });

  // Users
  const { users, isLoadingUsers } = useUsers({});
  const user = users?.at(0);

  // Relationships
  const { relationships, isLoadingRelationships } = useRelationships({});
  const relationship = relationships?.find(
    (relationship) => relationship.id === elapsedSession?.relationshipId,
  );
  const programSlug = relationship?.programSlug;

  // Sessions
  const { sessions, isLoadingSessions } = useSessions({});

  // Time and date format
  const locale = getLanguageFromCMS(baseLanguage?.language_code);
  const FormattedTime = getTimeFormat(baseLanguage?.time_format);
  const FormattedDate = getFriendlyDateFormat(baseLanguage?.date_format);

  // Surveys
  const { surveys, isLoadingSurveys } = useSurveys({});

  const isLoadingPostSessionSurveyResources =
    isLoadingPrograms ||
    isLoadingRelationships ||
    isLoadingUsers ||
    isLoadingSessions ||
    isLoadingSurveys;

  const isDelayed =
    window.sessionStorage.getItem(`post-session-delay`) === 'true' ?? false;

  useEffect(() => {
    if (isLoadingPostSessionSurveyResources) {
      return;
    }

    const now = new Date();

    const sessionWithoutSurvey = sessions?.find((session) => {
      const sessionEndDate = new Date(session.end);
      const hasSessionElapsed = sessionEndDate < now;
      const relationship = relationships?.find(
        (relationship) => relationship.id === session.relationshipId,
      );
      const isGuide = relationship?.guideProfiles?.at(0)?.userId === user?.id;
      const sessionCount = relationship?.sessions?.length ?? 0;

      const program = programs.find(
        (program) => program.metadata.id.current === session.programSlug,
      );
      const survey = program?.surveys?.post_session_survey;
      const surveyFields = survey?.fields ?? [];
      const surveyFieldsByRole = surveyFields.filter((surveyField) =>
        isGuide
          ? isSurveyFieldForGuide(surveyField, sessionCount)
          : isSurveyFieldForTrainee(surveyField, sessionCount),
      );
      const surveyStartDate = survey?.start_date;

      const hasSessionEndedAfterSurveyStartDate =
        surveyStartDate &&
        isValidDate(surveyStartDate) &&
        sessionEndDate > new Date(surveyStartDate);
      const isNotPastSession = !session.pastSession;
      const hasNotProvidedSessionFeedback = !surveys?.find(
        (survey) =>
          survey.type === 'post-session' && survey.session === session.id,
      );

      return (
        survey?.enabled &&
        surveyFieldsByRole.length &&
        hasSessionElapsed &&
        relationship &&
        hasSessionEndedAfterSurveyStartDate &&
        hasNotProvidedSessionFeedback &&
        isNotPastSession
      );
    });

    setElapsedSession(sessionWithoutSurvey);
  }, [
    isLoadingPostSessionSurveyResources,
    relationships,
    user?.id,
    sessions,
    programs,
    surveys,
  ]);

  useEffect(() => {
    if (
      !isLoadingSurveys &&
      !isSurveySubmitted &&
      !surveyType &&
      elapsedSession
    ) {
      setSurveyType('post-session');
    }
  }, [
    isLoadingSurveys,
    isSurveySubmitted,
    surveyType,
    elapsedSession,
    setSurveyType,
  ]);

  const {
    title,
    description,
    heading,
    subheading,
    profile,
    surveyFields,
    isGuide,
    isGroupProgram,
    isSurveyEnabled,
    isOnGuiderVideoPage,
  } = useSurveyResources({
    surveyType: 'post_session_survey',
    relationship,
    user,
  });

  useEffect(() => {
    if (!isDelayed) {
      setOpen(true);
    }
  }, [isDelayed, open]);

  if (
    isSurveySubmitted ||
    surveyType !== 'post-session' ||
    !surveyType ||
    !programSlug ||
    !isSurveyEnabled ||
    !elapsedSession ||
    !surveyFields.length ||
    isOnGuiderVideoPage ||
    isDelayed
  ) {
    return <></>;
  }

  const sessionTime = elapsedSession.start;

  const time = sessionTime
    ? format(new Date(sessionTime), FormattedTime, { locale })
    : '';

  const date = sessionTime
    ? format(new Date(sessionTime), FormattedDate, { locale })
    : '';

  const interpolatedDescription = interpolate(description, {
    time,
    date,
  });

  function handleNotNow() {
    window.sessionStorage.setItem('post-session-delay', 'true');
    setOpen(false);
  }

  return (
    <SurveyModal
      open={open}
      title={title}
      description={interpolatedDescription}
      heading={heading}
      subheading={subheading}
      Avatar={
        !IsSafariOrIOS ? (
          isGroupProgram ? (
            <GroupAvatar width={64} height={64} size="48px" />
          ) : (
            <SurveyAvatar
              relationshipId={elapsedSession.relationshipId}
              isGuide={isGuide}
            />
          )
        ) : (
          <></>
        )
      }
    >
      <SurveyForm
        title={title}
        programSlug={programSlug}
        profile={profile?.id}
        type={'post-session'}
        session={elapsedSession.id}
        surveyFields={surveyFields}
        isGuide={isGuide}
        handleNotNow={handleNotNow}
      />
    </SurveyModal>
  );
}
