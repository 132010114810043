import { createTheme, Theme } from '@mui/material';
import { createContext, SetStateAction } from 'react';
import { theme } from 'styles';

interface IThemeContext {
  theme: Theme;
  setTheme: (value: SetStateAction<Theme>) => void;
}

const defaultTheme = createTheme(theme);

export const ThemeContext = createContext<IThemeContext>({
  theme: defaultTheme,
  setTheme: () => null,
});
