import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { IReview } from '@guider-global/shared-types';
import {
  Stack,
  Text,
  TextStack,
  Image,
  ToolTip,
  ButtonStack,
  Button,
} from '@guider-global/ui';
import {
  DownloadDone,
  Star,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { format, isAfter, subDays } from 'date-fns';
import { useLocalization, useReviews } from 'hooks';
import { useDispatch } from 'react-redux';
import { showAppAlert } from 'store/slices/appSlice';

interface ReviewsContainerProps {
  reviews: IReview[] | undefined;
  emptyStateAvatar: string | undefined;
  variant: 'received' | 'authored';
}

export function ReviewsContainer({
  reviews,
  emptyStateAvatar,
  variant,
}: ReviewsContainerProps) {
  // Hooks
  const dispatch = useDispatch();
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);

  // Base Language
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const reviewsText = baseLanguage?.settings?.reviews;

  // Reviews
  const { reqReviews, isLoadingReviews } = useReviews({});

  // Profile

  async function handleModeration(
    review: IReview,
    action: 'approve' | 'decline',
  ) {
    if (isLoadingReviews || !review) return;
    try {
      await reqReviews({
        method: 'PATCH',
        url: `/reviews/${review.id}`,
        data: {
          isApproved: action === 'approve' ? true : false,
        },
      });
    } catch (err: unknown) {
      dispatch(
        showAppAlert({
          severity: 'error',
          message: 'Error updating review',
          timeout: 3000,
        }),
      );
    }
  }

  if (!reviews || reviews.length === 0) {
    return (
      <Stack
        direction={'column'}
        alignItems={'center'}
        margin={'0 auto'}
        gap={0}
        spacing={0}
        width={'50%'}
        pt={2}
      >
        <Text
          variant="h3"
          text={
            variant === 'authored'
              ? reviewsText?.authored_empty_state_title
              : reviewsText?.received_empty_state_title
          }
          mb={2}
        />
        {emptyStateAvatar && (
          <Image src={emptyStateAvatar} fit="contain" width={'80%'} />
        )}
        <Text
          textAlign={'center'}
          variant="caption"
          text={
            variant === 'authored'
              ? reviewsText?.authored_empty_state_description
              : reviewsText?.received_empty_state_description
          }
        />
      </Stack>
    );
  }

  const sortedReviews = reviews?.sort((a, b) =>
    a?.createdAt && b?.createdAt && b?.createdAt > a?.createdAt ? 1 : -1,
  );

  return (
    <Stack direction={'column'}>
      {sortedReviews?.map((review) => {
        const createdAt = new Date(review?.createdAt as Date);
        const twoDaysAgo = subDays(new Date(), 2);

        const adminApprovedOrHiddenOverride =
          review?.adminApproved ?? undefined;

        const isOlderThan2Days = isAfter(twoDaysAgo, createdAt);

        const userActionRequired =
          review.isApproved === undefined &&
          adminApprovedOrHiddenOverride === undefined &&
          variant === 'received';
        const reviewHiddenByUser =
          review.isApproved !== undefined &&
          review.isApproved === false &&
          adminApprovedOrHiddenOverride === undefined &&
          variant === 'received';

        let tooltipText;

        switch (isOlderThan2Days) {
          case true:
            tooltipText = reviewsText?.old_review_tooltip;
            break;
          default:
            tooltipText = reviewsText?.new_review_tooltip;
        }

        return (
          <Box
            key={`${review.id}`}
            display={'flex'}
            flexDirection={'column'}
            p={2.5}
            gap={1.5}
            borderRadius={'10px'}
            bgcolor={'#F7F7F7'}
            sx={{
              border: `1px solid #E0E0E0`,
            }}
          >
            <Stack
              direction={'row'}
              gap={0}
              spacing={0}
              minHeight={'20%'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box>
                {Array.from({ length: review.rating }, () => (
                  <Star color="warning" />
                ))}
              </Box>

              {reviewHiddenByUser && (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  spacing={0}
                  gap={2}
                >
                  <Button
                    variant="text"
                    label={reviewsText?.unhide_button_label}
                    onClick={() => handleModeration(review, 'approve')}
                    startIcon={<Visibility />}
                    sx={{ height: 'auto !important', p: 0 }}
                  />
                  <ToolTip title={reviewsText?.hidden_review_tooltip} />
                </Stack>
              )}
              {userActionRequired && (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  spacing={0}
                  gap={2}
                >
                  <ButtonStack
                    gap={0}
                    spacing={0}
                    direction={'row'}
                    buttons={[
                      {
                        key: '1',
                        label: reviewsText?.hide_button_label,
                        variant: 'text',
                        startIcon: <VisibilityOff />,
                        color: 'warning',
                        size: 'small',
                        sx: { height: 'auto !important', p: 0 },
                        onClick: () => handleModeration(review, 'decline'),
                      },
                      {
                        key: '1',
                        label: reviewsText?.approve_button_label,
                        startIcon: <DownloadDone />,
                        variant: 'text',
                        color: 'success',
                        size: 'small',
                        sx: { height: 'auto !important', p: 0 },
                        onClick: () => handleModeration(review, 'approve'),
                      },
                    ]}
                  />
                  <ToolTip title={tooltipText} />
                </Stack>
              )}
              {adminApprovedOrHiddenOverride !== undefined && (
                <Text
                  text={
                    adminApprovedOrHiddenOverride
                      ? reviewsText?.approved_by_admin_label
                      : reviewsText?.hidden_by_admin_label
                  }
                  variant="subtitle1"
                  color={'text.secondary'}
                />
              )}
            </Stack>
            <TextStack
              size={'xs'}
              spacing={2}
              heading={{
                variant: 'body1Secondary',
                text:
                  variant === 'received'
                    ? `${review.authorProfile?.displayName}${
                        review?.authorProfile?.jobTitle
                          ? ' - ' + review.authorProfile.jobTitle
                          : ''
                      }`
                    : `${review.reviewedProfile?.displayName}${
                        review?.reviewedProfile?.jobTitle
                          ? ' - ' + review.reviewedProfile.jobTitle
                          : ''
                      }`,
              }}
              subtitles={[
                { variant: 'body1', text: review.reviewText },

                review.createdAt && {
                  variant: 'body2Secondary',
                  text:
                    `${format(new Date(review.createdAt), 'MMMM yyyy')}` ?? '',
                },
              ]}
            />
          </Box>
        );
      })}
    </Stack>
  );
}
