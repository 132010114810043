import { SuccessModal } from '@guider-global/ui';
import { useContext } from 'react';
import { SurveyModalsContext } from '../SurveyModalsContext';

import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { useLocalization } from 'hooks';

export function ThankYouSurveyModalContainer() {
  const { isSurveySubmitted, resetState } = useContext(SurveyModalsContext);
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  return (
    <SuccessModal
      paperSx={{ minWidth: { md: '600px', xs: '100vw' } }}
      open={isSurveySubmitted}
      heading={
        baseLanguage?.surveys?.response_submitted_modal?.title ?? 'Thank you!'
      }
      subheading={
        baseLanguage?.surveys?.response_submitted_modal?.description ??
        'Your rating has been received'
      }
      closeButtonLabel={
        baseLanguage?.globals?.common?.close_button_label ?? 'Close'
      }
      onClose={resetState}
    />
  );
}
