import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { INotification } from '@guider-global/shared-types';
import { Loading } from '@guider-global/ui';
import { useLocalization, useNotifications } from 'hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { NotificationModalView } from 'views/dashboard/NotificationModalView';
import { NotificationCardContainer } from '../notificationCardContainer';

export type NotificationModalContainerProps = {
  onClose: () => void;
};

export function NotificationModalContainer({
  onClose,
}: Readonly<NotificationModalContainerProps>) {
  const pageLimit = 10;
  const loaderRef = useRef<HTMLDivElement | null>(null);
  const [page, setPage] = useState(1);
  const [allNotifications, setAllNotifications] = useState<INotification[]>([]);

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { notifications, isLoadingNotifications, reqNotifications } =
    useNotifications({
      options: { keepPreviousData: true },
      params: { pageLimit, page },
    });

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      setAllNotifications((prevNotifications) => [
        ...prevNotifications,
        ...notifications.filter(
          (notifications) =>
            !prevNotifications.some(
              (notification) => notification.id === notifications.id,
            ),
        ),
      ]);
    }
  }, [notifications]);

  const shouldLoadNextPage = notifications?.length === pageLimit;

  const [viewedNotificationIds, setViewedNotificationIds] = useState<string[]>(
    [],
  );

  function handleNotificationVisible(notification: INotification) {
    setViewedNotificationIds((prevViewedNotificationIds) => {
      if (
        notification.status === 'unread' &&
        !prevViewedNotificationIds.includes(notification.id)
      ) {
        return [...prevViewedNotificationIds, notification.id];
      }

      return prevViewedNotificationIds;
    });
  }

  const markViewedNotificationsRead = useCallback(async () => {
    if (viewedNotificationIds.length === 0) {
      return;
    }

    await reqNotifications({
      method: 'PATCH',
      url: '/notifications',
      params: { id: viewedNotificationIds },
      data: { status: 'read' },
    });

    setViewedNotificationIds([]);
  }, [reqNotifications, viewedNotificationIds]);

  function handleClose() {
    markViewedNotificationsRead();
    if (onClose) {
      onClose();
    }
  }

  useEffect(() => {
    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const handleObserver: IntersectionObserverCallback = (entries) => {
      const entry = entries[0];
      if (
        entry.isIntersecting &&
        shouldLoadNextPage &&
        !isLoadingNotifications
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (loaderRef.current) {
        observer.disconnect();
      }
    };
  }, [isLoadingNotifications, shouldLoadNextPage]);

  const headingText = baseLanguage?.dashboard?.notifications?.modal?.title;
  const closeButtonLabel =
    baseLanguage?.globals?.common?.close_button_label ?? '';

  return (
    <NotificationModalView
      open={true}
      headingText={headingText}
      closeButtonLabel={closeButtonLabel}
      onClose={handleClose}
    >
      {allNotifications?.map((notification) => (
        <NotificationCardContainer
          key={notification.id}
          notification={notification}
          onVisible={() => handleNotificationVisible(notification)}
        />
      ))}
      <div ref={loaderRef} style={{ margin: 0 }} />
      <Loading
        isLoading={isLoadingNotifications}
        color="white"
        sx={{ padding: '2%' }}
      />
    </NotificationModalView>
  );
}
