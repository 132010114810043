import { Text } from '@guider-global/ui';
import { format } from 'date-fns';

export type NotificationBubbleDateLabelProps = {
  show: boolean;
  date: string | Date;
};

export function NotificationBubbleDateLabel({
  show = true,
  date,
}: NotificationBubbleDateLabelProps) {
  const dayFormatted = format(new Date(date), 'dd');
  const monthFormatted = format(new Date(date), 'MMM');
  return show
    ? [
        <Text
          key="label-a"
          variant="h4"
          text={dayFormatted}
          sx={{ color: 'inherit' }}
        />,
        <Text
          key="label-b"
          variant="h5"
          text={monthFormatted}
          sx={{ color: 'inherit' }}
        />,
      ]
    : undefined;
}
