import { PropsWithChildren, createContext, useMemo } from 'react';
import { useLocalization, useSessions } from 'hooks';
import { compareDesc } from 'date-fns';
import {
  EProgramVariation,
  IGroup,
  IMembership,
  IProfile,
  IRelationship,
  Program,
} from '@guider-global/shared-types';
import { theme } from '@guider-global/ui';
import {
  useBaseLanguage,
  useOrganization,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { Stack, ThemeProvider, useTheme } from '@mui/system';
import { deepMerge } from 'utils';
import { Background } from 'components';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useMemberships,
  useProfiles,
  useRelationships,
} from '@guider-global/front-end-hooks';

export interface DashboardNotificationsContextProps {
  membership?: IMembership;
  relationship?: IRelationship;
  profile?: IProfile;
  hasMemberships: boolean;
  hasRelationships: boolean;
  hasSessions: boolean;
  hasAttendedSessions: boolean;
  hasCompletedOnboarding: boolean;
  program?: Program;
  programSlug: string;
  programName: string;
  relationshipId: string;
  sessionId: string;
  isGuide: boolean;
  isGroupProgram: boolean;
  isActiveGroup: boolean;
  isEmptyGroup: boolean;
  isLoading: boolean;
  hasOpenMatchingRelationship: boolean;
  hasOpenMatchingRole: boolean;
}

export const DashboardNotificationsContext =
  createContext<DashboardNotificationsContextProps>({
    membership: undefined,
    relationship: undefined,
    hasMemberships: false,
    hasRelationships: false,
    hasSessions: false,
    hasAttendedSessions: false,
    hasCompletedOnboarding: false,
    program: undefined,
    programSlug: '',
    programName: '',
    relationshipId: '',
    sessionId: '',
    isGuide: false,
    isGroupProgram: false,
    isActiveGroup: false,
    isEmptyGroup: false,
    isLoading: false,
    hasOpenMatchingRelationship: false,
    hasOpenMatchingRole: false,
  });

export function DashboardWrapper({ children }: Readonly<PropsWithChildren>) {
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);

  // Membership
  const { memberships, isLoadingMemberships } = useMemberships({});
  const { organization } = useOrganization({ organizationSlug });

  const membership = memberships?.at(0);
  const hasMemberships = (memberships && memberships.length > 0) || false;
  const programSlug = membership?.programSlug ?? '';

  // Program
  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(programSlug ?? '');
  const organizationTheme = useTheme();

  const { profiles, isLoadingProfiles } = useProfiles({});
  const profile = profiles?.at(0);

  const { isLoadingBaseLanguage } = useBaseLanguage({ localeCode });

  const programName = program?.metadata.program_name ?? '';

  const isGuide = membership?.role === 'guide';

  const { relationships, isLoadingRelationships } = useRelationships({});

  const activeRelationships = relationships?.filter(
    (relationship) => !relationship.isConcluded,
  );
  const [relationship] = activeRelationships ?? [];
  const relationshipId = relationship?.id ?? '';
  const hasRelationships =
    (activeRelationships && activeRelationships?.length > 0) || false;

  const hasOpenMatchingRelationship =
    hasRelationships && relationship.programSlug === undefined;

  const isOpenMatchingEnabled =
    organization?.open_matching?.enable_open_matching ?? false;
  const hasOpenMatchingRole =
    profile?.roles?.length !== 0 && isOpenMatchingEnabled;

  const isGroupProgram =
    program?.program_details?.program_variation === EProgramVariation.Group;

  const group = relationship?.group as Partial<IGroup>;
  const isActiveGroup =
    group?.activatedAt !== null && group?.activatedAt !== undefined;
  const isEmptyGroup = relationship?.traineeProfiles?.length === 0;

  const { sessions = [], isLoadingSessions } = useSessions({});
  const session = sessions.at(0);
  const hasSessions = sessions.length > 0;
  const attendedSessions = sessions.filter(
    (session) => compareDesc(new Date(session.end), new Date()) === 1,
  );
  const hasAttendedSessions = attendedSessions.length > 0;
  const sessionId = session?.id ?? '';

  const hasCompletedOnboarding = hasAttendedSessions; // if user has attended a session, it means they have completed onboarding

  const isLoading =
    isLoadingMemberships ||
    isLoadingRelationships ||
    isLoadingSessions ||
    isLoadingProfiles ||
    isLoadingBaseLanguage;

  const value = useMemo(
    () => ({
      membership,
      relationship,
      hasMemberships,
      hasRelationships,
      hasSessions,
      hasAttendedSessions,
      hasCompletedOnboarding,
      program,
      programSlug,
      programName,
      relationshipId,
      sessionId,
      isGuide,
      isGroupProgram,
      isActiveGroup,
      isEmptyGroup,
      isLoading,
      hasOpenMatchingRelationship,
      profile,
      hasOpenMatchingRole,
    }),
    [
      membership,
      relationship,
      hasMemberships,
      hasRelationships,
      hasSessions,
      hasAttendedSessions,
      hasCompletedOnboarding,
      program,
      programSlug,
      programName,
      relationshipId,
      sessionId,
      isGuide,
      isGroupProgram,
      isActiveGroup,
      isEmptyGroup,
      isLoading,
      hasOpenMatchingRelationship,
      profile,
      hasOpenMatchingRole,
    ],
  );

  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  return (
    <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
      <DashboardNotificationsContext.Provider value={value}>
        <Stack
          direction={'column'}
          py={{ xs: 3, md: 6 }}
          px={{ xs: 3, md: 12 }}
          gap={3}
          spacing={0}
          width={'100%'}
          alignItems={'center'}
          sx={{
            backgroundColor: '#F9FAFB',
          }}
        >
          <Background />
          {children}
        </Stack>
      </DashboardNotificationsContext.Provider>
    </ThemeProvider>
  );
}
