import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { EProgramVariation, ICustomField } from '@guider-global/shared-types';
import { MainColors } from '@guider-global/ui';
import { OnboardingProgram, OnboardingProgramView } from 'views';
import { useMemberships, useProfiles } from '@guider-global/front-end-hooks';
import { useLocalization } from 'hooks';

export function OnboardingProgramContainer() {
  // Profiles
  const { profiles } = useProfiles({});
  const profile = profiles?.at(0);
  const profileOrganizationFields =
    (profile?.organizationFields as ICustomField[] | undefined) ?? [];

  // Memberships
  const { memberships } = useMemberships({});

  // Programs
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { getAuthorizedPrograms } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const authorizedPrograms = getAuthorizedPrograms(
    profileOrganizationFields,
    memberships,
  );

  const ctaPrograms: OnboardingProgram[] = authorizedPrograms?.map(
    (authorizedProgram) => {
      const name = authorizedProgram?.metadata?.program_name;
      const description =
        authorizedProgram.program_details?.program_description;
      const image = authorizedProgram?.program_details
        ? buildSanityImageUrl({
            source: authorizedProgram?.program_details?.program_image,
            width: 600,
          })
        : undefined;

      let joinAsMenteeButton;
      let joinAsMentorButton;

      const variation = authorizedProgram?.program_details?.program_variation;

      if (variation === EProgramVariation.Group) {
        joinAsMenteeButton =
          authorizedProgram?.program_details?.program_type?.program_type_text
            ?.variations?.group?.registration?.registration_landing_page
            ?.trainee_cta_button_label;
        joinAsMentorButton =
          authorizedProgram?.program_details?.program_type?.program_type_text
            ?.variations?.group?.registration?.registration_landing_page
            ?.guide_cta_button_label;
      } else {
        joinAsMenteeButton =
          authorizedProgram?.program_details?.program_type?.program_type_text
            ?.variations?.individual?.registration?.registration_landing_page
            ?.trainee_cta_button_label;
        joinAsMentorButton =
          authorizedProgram?.program_details?.program_type?.program_type_text
            ?.variations?.individual?.registration?.registration_landing_page
            ?.guide_cta_button_label;
      }

      const programSlug = authorizedProgram?.metadata?.id?.current;
      const displayPreference =
        authorizedProgram?.program_details?.display_preference;
      const type =
        authorizedProgram?.program_details?.program_type?.program_type_text
          ?.common?.verb;

      let chipColor: MainColors;

      if (type === 'Mentoring') {
        chipColor = 'success';
      } else if (type === 'Coaching') {
        chipColor = 'info';
      } else {
        chipColor = 'primary';
      }

      return {
        name,
        description,
        image,
        type,
        displayPreference,
        programSlug,
        joinAsMenteeButton,
        joinAsMentorButton,
        chipColor,
        variation,
      };
    },
  );

  return <OnboardingProgramView program={ctaPrograms.at(0)} />;
}
