import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { ButtonStack, Image, Stack, TextStack } from '@guider-global/ui';
import NoNotifications from 'assets/empty_notifications.svg';
import { useLocalization } from 'hooks';
import { useNotifications } from 'hooks/useNotifications';
import { useNavigate } from 'react-router-dom';
import { NotificationCardContainer } from '../notificationCardContainer';

export function NotificationHighlightsContainer() {
  const navigate = useNavigate();
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { notifications } = useNotifications({
    params: { pageLimit: 3, page: 1, latest: true },
    options: { keepPreviousData: true },
  });

  if (notifications?.length === 0) {
    return (
      <Stack direction={'column'} alignItems={'center'} p={3} pb={6} gap={1}>
        <Image src={NoNotifications} height={'160px'} width={'240px'} />
        <TextStack
          spacing={1}
          size="medium"
          heading={baseLanguage?.dashboard?.notifications?.empty_state?.title}
          align="center"
          subtitles={[
            {
              text: baseLanguage?.dashboard?.notifications?.empty_state
                ?.description,
              textAlign: 'center',
              color: 'text.secondary',
            },
          ]}
        />
        <ButtonStack
          width={{
            xs: '100%',
            md: '70%',
          }}
          direction={{ xs: 'column', md: 'row' }}
          spacing={1.5}
          buttons={[
            {
              key: 'empty-notifications-btn-1',
              variant: 'contained',
              label:
                baseLanguage?.dashboard?.notifications?.empty_state?.view_goals,
              onClick: () => navigate('/relationships'),
              color: 'info',
              sx: { px: 3 },
            },
            {
              key: 'empty-notifications-btn-2',
              variant: 'outlined',
              label:
                baseLanguage?.dashboard?.notifications?.empty_state
                  ?.explore_learning_hub,
              onClick: () => navigate('/learn'),
              color: 'info',
              sx: {
                px: 3,
              },
            },
          ]}
        />
      </Stack>
    );
  }

  return notifications?.map((notification) => (
    <NotificationCardContainer
      key={notification.id}
      notification={notification}
    />
  ));
}
