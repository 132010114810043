import { useContext, useState } from 'react';
import { DashboardNotificationsContext } from '../DashboardWrapper';
import { DashboardCard, Image, Stack } from '@guider-global/ui';
import { OnboardingChecklistContainer } from '../onboardingChecklistContainer';
import { NotificationModalContainer } from '../NotificationModalContainer';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import Arrow from 'assets/arrow-icon.svg';
import { NotificationsContainer } from '../NotificationsContainer';
import { ProgressLeaderboardContainer } from '../progressLeaderboardContainer';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useLocalization } from 'hooks';

export function HeroSectionContainer() {
  const [showNotificationModal, setShowNotificationModal] =
    useState<boolean>(false);

  // BaseLanguage
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { hasSessions, hasAttendedSessions, hasCompletedOnboarding } =
    useContext(DashboardNotificationsContext);

  const showAllNotifications =
    hasCompletedOnboarding ||
    (!hasCompletedOnboarding && hasSessions && !hasAttendedSessions);

  const openNotificationModal = () => {
    setShowNotificationModal(true);
  };

  const closeNotificationModal = () => {
    setShowNotificationModal(false);
  };

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      width={{ xs: '100%', md: '1136px' }}
      height={{ xs: 'max-content', md: 'inherit' }}
      alignItems={'stretch'}
      justifyContent={'stretch'}
      gap={3}
      spacing={0}
    >
      <DashboardCard
        zIndex={1}
        title={baseLanguage?.dashboard?.notifications?.panel?.title}
        width={{ xs: '100%', md: '66%' }}
        backgroundColor={hasCompletedOnboarding ? '#F9FAFB' : '#FEFEFE'}
        cta={
          showAllNotifications
            ? {
                label:
                  baseLanguage?.dashboard?.notifications?.panel
                    ?.view_all_notifications_button_label,
                icon: <Image src={Arrow} height={'20px'} width={'20px'} />,
                onClick: openNotificationModal,
              }
            : undefined
        }
      >
        <NotificationsContainer />
      </DashboardCard>
      <DashboardCard
        zIndex={1}
        title={baseLanguage?.dashboard?.leaderboard?.card?.title}
        width={{ xs: '100%', md: '33%' }}
        backgroundColor="#FEFEFE"
      >
        {hasCompletedOnboarding ? (
          <ProgressLeaderboardContainer />
        ) : (
          <OnboardingChecklistContainer />
        )}
      </DashboardCard>
      {showNotificationModal && (
        <NotificationModalContainer onClose={closeNotificationModal} />
      )}
    </Stack>
  );
}
